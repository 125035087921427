<template>
  <!-- 视频 -->
  <a-modal
    :title="$t('Pub_Video')"
    :width="900"
    :bodyStyle="{ padding: '12px' }"
    v-model:visible="visible"
    :footer="null"
  >
    <div class="video-box">
      <video
        :src="
          (url.slice(0, url.lastIndexOf('.')) + '/1/mp4/1_1.mp4').replace(
            '/source/',
            '/'
          )
        "
        autoplay
        controls
        controlslist="nodownload"
        v-if="visible"
      ></video>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  name: "previewVideo",
  props: {
    onlyPics: {
      type: Boolean,
      default: false,
    },
  },
  setup(_) {
    const state = reactive({
      visible: false,
      url: "",
    });

    const openModal = (url) => {
      state.visible = true;
      state.url = url;
    };

    return {
      ...toRefs(state),
      openModal,
    };
  },
};
</script>

<style lang="less" scoped>
.video-box {
  width: 100%;
  height: 530px;
  background-color: #f5f5f5;
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
